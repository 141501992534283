import React from 'react';

const WorldlyIcon = (props) => (
  <svg
    fill="currentColor"
    viewBox="0 0 100 100"
    width="1em"
    height="1em"
    {...props}
  >
    <ellipse cx={49.8} cy={22.8} rx={17} ry={17.8} />
    <path d="M83.1 57.7c-4.2-13.1-22.2-16-24.5-16.6-5.1 4-12.3 4-17.4 0-2.3.5-20.7 3.5-24.6 17.1 0 0-8.2 24.4 1.8 29.5 0 0 2.7 2.6 10.7-2.9.4-.2.7-.5 1-.8 4.3 6.6 11.8 11 20.2 11 8.3 0 15.7-4.3 20.1-10.7.2.2.5.3.7.5 8 5.5 10.7 2.9 10.7 2.9 9.9-5.1 1.3-30 1.3-30zm-46.5 12l-.1-.1c-2.3-2.8-6.9-1.2-7.9-.8.4-4.3 2.1-8.3 4.7-11.5.9.9 2.7 2.4 5.2 3.7-.8 2.5-1.3 5.4-1.5 8.8h-.4zm26.3-9.2c2.1-1.2 3.6-2.4 4.4-3.2 2.6 3.3 4.3 7.3 4.7 11.8l-.3-.2s-4.5-2-7.3 0c-.1-3.3-.7-6.1-1.5-8.4zM49.1 50v11.3c-3-.1-5.5-.8-7.6-1.7 2.2-5.2 5.6-8.2 7.6-9.6zm-9.7 8.6c-2.2-1.2-3.7-2.5-4.5-3.2 3-3 6.8-5.1 11.1-6-2.2 2-4.8 4.9-6.6 9.2zm12.1-9.3c1.7 1 5.8 4 8.4 10-2.2 1-5.1 1.8-8.4 2v-12zm4.5.5c3.7 1 7.1 3 9.7 5.6-.7.6-1.9 1.7-3.7 2.7-1.7-3.7-4-6.5-6-8.3zM40.7 61.9c2.3.9 5.1 1.6 8.4 1.8v6h-9.7c.1-3 .6-5.6 1.3-7.8zm10.8 1.8c3.7-.2 6.8-1 9.3-2.1.8 2.3 1.3 5 1.4 8.1H51.5v-6zM49.1 72v6.3c-3.2.1-6 .8-8.3 1.7-.8-2.3-1.3-5-1.4-8.1h9.7zm0 8.7v11c-2-1.4-5.3-4.4-7.5-9.4 2.1-.8 4.6-1.4 7.5-1.6zm-3.2 11.5c-4.2-.9-7.9-2.9-10.9-5.8.8-.8 2.3-2 4.4-3.1 1.9 4.2 4.4 7.1 6.5 8.9zm5.6.2V80.7c3.3.2 6.1 1 8.3 2-2.6 5.8-6.7 8.7-8.3 9.7zm10.4-8.7c1.7 1 2.9 2 3.7 2.7-2.6 2.6-5.9 4.5-9.5 5.5 1.9-1.8 4.1-4.4 5.8-8.2zm-10.4-5.3V72h10.7c-.1 3.3-.7 6-1.5 8.4-2.5-1-5.6-1.9-9.2-2zm-14.2-3.7c.3 2.4.7 4.5 1.4 6.4-2.5 1.3-4.2 2.7-5.2 3.6-.6-.7-1.1-1.4-1.5-2.1 2.9-2.7 4.7-5.5 5.3-7.9zm29.9 10c-.9-.8-2.3-2-4.4-3.1.5-1.3.8-2.7 1.1-4.2 1 1.8 2.5 3.7 4.6 5.5-.4.5-.9 1.2-1.3 1.8z" />
  </svg>
);

export default WorldlyIcon;
