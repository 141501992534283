import React from 'react';

const PaintbrushIcon = (props) => (
  <svg
    fill="currentColor"
    viewBox="0 0 100 100"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.651 73.347a130.934 130.934 0 0 1-6.332-5.811C9.592 72.107 26.872 89.325 7.928 91.593c20.249 4.167 34.853-.24 34.424-12.849a130.06 130.06 0 0 1-6.701-5.397z"
    />
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M42.451 65.742a109.667 109.667 0 0 1-5.846-5.399c-1.678 1.988-3.138 3.768-4.37 5.377a133.21 133.21 0 0 0 12.088 10.332c1.377-1.483 2.881-3.219 4.545-5.188a108.8 108.8 0 0 1-6.417-5.122zM44.286 63.609a110.179 110.179 0 0 0 6.387 5.1c2.594-3.107 5.555-6.709 9.012-10.784C76.587 37.998 94.025 10.42 91.551 8.321c-2.475-2.1-27.823 20.141-44.726 40.067-3.161 3.726-5.968 6.964-8.397 9.808a108.57 108.57 0 0 0 5.858 5.413z" />
    </g>
  </svg>
);

export default PaintbrushIcon;
